<template>
  <div>
    <b-tabs pills card vertical class="tabs-form" content-class="tab-form-content" nav-class="form-nav-tabs pl-0" active-nav-item-class="theme-font-bold">
      <template v-for="(tab, tIndex) in formTabs">
        <b-tab
          title-link-class="pl-0"
          :title="tab.label"
          :active="activeTab.value == tab.value"
          v-if="tab.display"
          :key="tIndex"
          @click="setActiveTab(tab, tIndex)"
          lazy
        >
          <template #title>
            <div class="d-flex justify-content-between align-items-center">
              <div class="alert-icon">
                {{ tab.label }}
                <BIconExclamationCircleFill scale="0.8" v-if="tab.isVisited && tab.hasErrors" variant="danger" class="ml-1 visible" />
                <BIconExclamationCircleFill scale="0.8" v-else class="ml-1 invisible" variant="danger" />
              </div>
              <div class="arrow-right-icon">
                <ArrowRight v-if="activeTab.value == tab.value" />
              </div>
            </div>
          </template>
          <h3 class="font-21 theme-font-medium pb-2">
            {{ activeTab.label }}
          </h3>
          <b-form :id="`add-edit-${formName}-from`" :name="`add-edit-${formName}-from`" autocomplete="off" @keyup.tab="pageScroll" novalidate>
            <keep-alive>
              <component :is="activeTab.componentName" :formPayload="formPayload" :formSubmitted="tab.isVisited" :isEditPage="isEditPage"></component>
            </keep-alive>
          </b-form>
        </b-tab>
      </template>
    </b-tabs>
    <div class="tab-form-actions-btns px-5 d-flex justify-content-between">
      <div class="back">
        <b-button
          type="button"
          id="tab-form-back-btn"
          v-if="showBackButton"
          v-activeBlur
          variant="outline-secondary"
          @click="actionNavigation('back')"
          class="btn-h-58"
        >
          <ChevronLeftIcon class="mr-2" />
          Back
        </b-button>
      </div>
      <template>
        <div class="save" v-show="showSaveButton">
          <b-button type="button" id="tab-form-save-btn" v-activeBlur :disabled="saveLoadingIcon" @click="onSubmitTabForm" variant="primary" class="btn-h-58"
            >Save<b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
          ></b-button>
        </div>
        <div class="next" v-show="!showSaveButton">
          <b-button type="button" id="tab-form-next-btn" v-activeBlur variant="primary" class="btn-h-58" @click="actionNavigation('next')"
            >Next <ChevronRightIcon class="ml-1"
          /></b-button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { useValidateFields } from '@/composables/useValidateFields'
import { BIconExclamationCircleFill } from 'bootstrap-vue'
import ArrowRight from '@/assets/svg/arrow-right.svg'
import ChevronLeftIcon from '@/assets/svg/chevron-left.svg'
import ChevronRightIcon from '@/assets/svg/chevron-right.svg'
export default {
  name: 'TabForm',
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()

    return { validateUrl, scrollToErrorMessage }
  },
  props: {
    formName: {
      type: String,
      default: '',
    },
    formTabs: {
      type: Array,
      required: true,
    },
    isEditPage: {
      type: Boolean,
      default: false,
    },
    formPayload: {
      type: Object,
      required: true,
    },
    saveLoadingIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: {},
    }
  },
  components: {
    BIconExclamationCircleFill,
    ArrowRight,
    ChevronLeftIcon,
    ChevronRightIcon,
    LinkAccountConfiguration: () => import('@/components/settings/link-accounts/add-link-account/Configuration.vue'),
    LinkAccountAPI: () => import('@/components/settings/link-accounts/add-link-account/API.vue'),
  },
  computed: {
    showBackButton() {
      return this.activeTab.index !== 0
    },
    showSaveButton() {
      return this.activeTab.index === this.formTabs.length - 1
    },
  },
  mounted() {
    this.activeTab = this.formTabs[0]
    this.activeTab.index = 0
  },
  methods: {
    pageScroll(event) {
      // tab = 9
      if (event.keyCode === 9) {
        const position = event.target.getBoundingClientRect()
        const padding = 133 // 85 + 48 = 133 action fixed footer height = 85 and input field height 48
        const viewHeight = window.innerHeight - padding
        const scrollPosition = window.top.scrollY
        if (position.top > viewHeight) {
          window.scrollTo(0, scrollPosition + padding)
        }
      }
    },
    actionNavigation(type) {
      const index = type === 'back' ? this.activeTab.index - 1 : this.activeTab.index + 1
      const actionSelectedTab = this.formTabs[index]
      this.setActiveTab(actionSelectedTab, index)
    },
    setActiveTab(selectedTab, index) {
      this.formTabs[this.activeTab.index].isVisited = true
      this.activeTab = selectedTab
      this.activeTab.index = index
      this.scrollToErrorMessage()
      this.$emit('validateForm')
    },
    onSubmitTabForm() {
      this.formTabs[this.activeTab.index].isVisited = true
      this.$emit('submit')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .tabs-form {
  margin-bottom: -1.5rem;
  margin-top: -1.5rem;
}
::v-deep .tab-form-content {
  .tab-pane {
    padding: 2.5rem 1.75rem !important;
  }
}
::v-deep .form-nav-tabs {
  border-right: 1px solid #d6d6d6;
  background-color: #fff;
  padding-top: 1.75rem;
  padding-right: 2.5rem;
  a {
    font-size: 18px;
    color: #000000;
  }
  .arrow-right-icon {
    position: absolute;
    right: 25px;
  }
}
.tab-form-actions-btns {
  position: fixed;
  margin: 0 -3rem;
  bottom: 0;
  width: 100%;
  height: 85px;
  background-color: #fff;
  align-items: center;
  z-index: 1001;
  box-shadow: 0 0 20px #00000012;
}
.b-sidebar-body {
  .tab-form-actions-btns {
    margin: 0 -1.5rem;
  }
}
</style>
